import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=c56cd5b2&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c56cd5b2",
  null
  
)

export default component.exports